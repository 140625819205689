<template>
  <CardList
    :items="posts"
    :card="ArticleCard"
    :placeholders="limit"
    :loading="$apollo.loading"
  />
</template>

<script>
import { POST_LIST } from "@/models/resources/operations.gql";
import CardList from "@/components/common/CardList.vue";
import ArticleCard from "@/components/resources/ArticleCard.vue";

export default {
  name: "ArticleList",
  components: {
    CardList
  },
  props: {
    where: {
      type: Object,
      default: null
    },
    limit: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      ArticleCard,
      posts: []
    };
  },
  apollo: {
    posts: {
      client: "cms",
      query: POST_LIST,
      variables() {
        const stage =
          !!localStorage.showCMSDrafts && JSON.parse(localStorage.showCMSDrafts)
            ? "DRAFT"
            : "PUBLISHED";
        return {
          skip: 0,
          limit: this.limit,
          where: this.where,
          stage
        };
      },
      update({ posts }) {
        //this.$log.debug("got some posts", posts);
        return posts;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.description-heading {
  font-size: 28px;
  font-weight: 500;
  color: #43444a;
}
</style>
