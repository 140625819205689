export const wherePostHasRole = role => ({
  collections_every: { role }
});

export const whereIsFeatured = featured => ({
  featured
});

export const whereCollectionHasRole = role => ({
  role
});
