<template>
  <div>
    <section>
      <div class="article-search-container">
        <b-input
          v-model="searchTerm"
          class="article-search-bar"
          type="text"
          icon="search-location"
          placeholder="What are you looking for?"
        ></b-input>
      </div>
    </section>

    <section v-if="featured && featured.length" class="hero is-large pb-2">
      <h2 class="mb-1">
        Featured Articles
      </h2>
      <b-carousel
        :interval="5000"
        :pause-info="false"
        :indicator-inside="false"
        :indicator-background="false"
      >
        <b-carousel-item v-for="article in featured" :key="article.id" class="article-carousel-item">
          <div class="columns">
            <div class="card is-flex column-container">
              <div class="column article-image">
                <router-link
                  :to="{
                    name: 'article',
                    params: { slug: article.slug }
                  }"
                >
                  <div :style="`${article.media[0].url ? `background-image: url(${article.media[0].url})` : ''}`"></div>
                </router-link>
              </div>
              <div class="column article-info">
                <router-link
                  :to="{
                    name: 'article',
                    params: { slug: article.slug }
                  }"
                >
                  <h2>{{ article.title }}</h2>
                </router-link>
                <p class="byline">by 
                  <router-link
                    :to="{
                      name: 'user_profile',
                      params: { username: article.authors[0].username }
                    }"
                  >
                  {{ article.authors[0].name }}
                  </router-link>
                </p>
                <div class="summary">
                  <router-link
                    :to="{
                      name: 'article',
                      params: { slug: article.slug }
                    }"
                  >
                    <p>{{ article.summary }}</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </b-carousel-item>
      </b-carousel>
    </section>
    <!-- <section>
      <h2>Filter by Category</h2>
      <CollectionList :where="where.collection" />
    </section> -->

    <section>
      <h2>Recent Articles</h2>
      <ArticleList v-if="!searchTerm" :where="where.recent" />
      <ArticleList v-else :where="where.filtered" />
    </section>

    <div
      v-if="!$auth.isAthenticated"
      class="boxed mb-4 mt-5 p-3"
      style="width: 60%; margin-left: auto; margin-right: auto;"
    >
      <h4 class="has-text-centered is-size-4 has-text-weight-medium">
        Not a member of Shut Up & Write!?
      </h4>
      <p class="has-text-centered is-size-6 has-text-grey">
        Sign up for a free membership and find the support to keep you writing.
      </p>
      <div class="is-flex is-justify-content-center mt-3">
        <div class="button is-info has-text-weight-bold mr-3" @click="login">
          Sign Up Now
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toLocalDate } from "@/dates";
import { format } from "date-fns";
import { POST_LIST } from "@/models/resources/operations.gql";
import {
  wherePostHasRole,
  //whereCollectionHasRole,
  whereIsFeatured
} from "@/models/resources/predicates.js";
import ArticleList from "@/components/resources/ArticleList.vue";
//import CollectionList from "@/components/resources/CollectionList.vue";

export default {
  name: "GeneralTab",
  components: {
    ArticleList
    //CollectionList
  },
  data() {
    return {
      searchTerm: null,
      toLocalDate,
      format
    };
  },
  computed: {
    where() {
      return {
        //collection: whereCollectionHasRole("Public"),
        recent: {
          ...wherePostHasRole("Public")
          // Need "where publishedAt > X months ago" predicate
        },
        filtered: {
          title_contains: this.searchTerm
        }
      };
    }
  },
  methods: {
    login() {
      this.$auth.refreshAccess({ redirectUrl: this.$route.fullPath });
    }
  },
  apollo: {
    featured: {
      client: "cms",
      query: POST_LIST,
      variables() {
        const stage = !!localStorage.showCMSDrafts && JSON.parse(localStorage.showCMSDrafts) ? "DRAFT" : "PUBLISHED";
        return {
          first: this.limit,
          stage,
          where: {
            ...wherePostHasRole("Public"),
            ...whereIsFeatured(true)
          }
        };
      },
      update({ posts }) {
        return posts;
      }
    }
  }
};
</script>
<style lang="scss">
@import "../../scss/mixins";
.carousel {
  .article-carousel-item {
    padding: 2em 0;
    @include mobile {
      padding: vwl(15px) 0;
    }
    @include portrait {
      padding: vwp(15px) 0;
    }
  }
  .column-container {
    .column {
      background-color: white;
      border-radius: .75em;
    }
  }
  .columns {
    margin: 0;
  }
  .article-image {
    flex: 0 0 auto;
    position: relative;
    padding: 1em 1em 0 1em;
    @include tablet {
      width: 390px;
      padding: 1em;
    }
    @include desktop {
      width: calc(50% + 1em);
    }
    div {
      height: 0;
      padding-bottom: 50%;
      width: 100%;
      background-position: 50% 50%;
      background-size: calc(100% + 8px);
    }
  }
  .article-info {
    flex: 1 1 auto;
    padding: 2em 2em 2em 0.5em;
    @include mobile {
      padding: 1em 2em 2em 1em;
    }
    @include desktop {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    p {
      font-size: 1.25em;
      @include mobile {
        font-size: vwl(14px);
      }
      @include portrait {
        font-size: vwp(14px);
      }
    }
  }
  .byline {
    margin: .5rem 0 1.5rem 0;
    @include mobile {
      margin: vwl(8px) 0 vwl(16px) 0;
    }
    @include portrait {
      margin: vwp(8px) 0 vwp(16px) 0;
    }
  }
  .summary {
    position: relative;
    @include mobile {
      height: 0;
      padding-bottom: 15%;
      overflow: hidden;
    }
    @include portrait {
      padding-bottom: 55%;
    }
  }
}
.article-search-container {
  display: flex;
  padding: 1rem 0 2rem 0;
  justify-content: space-around;
  @include mobile {
    padding: vwl(16px) 0;
  }
  @include portrait {
    padding: vwp(16px) 0;
  }
  @include desktop {
    padding: 1rem 0 3rem 0;
  }
}
.article-search-bar {
  width: 100%;
}
h2 {
  color: $grey900;
  font-weight: normal;
}
p {
  color: $grey900;
}
section {
  > h2 {
    margin: 0 0 1rem 0;
    @include mobile {
      margin: vwl(16px) 0;
    }
    @include portrait {
      margin: vwp(16px) 0;
    }
  }
}
</style>
