var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_c('div',{staticClass:"article-search-container"},[_c('b-input',{staticClass:"article-search-bar",attrs:{"type":"text","icon":"search-location","placeholder":"What are you looking for?"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),(_vm.featured && _vm.featured.length)?_c('section',{staticClass:"hero is-large pb-2"},[_c('h2',{staticClass:"mb-1"},[_vm._v(" Featured Articles ")]),_c('b-carousel',{attrs:{"interval":5000,"pause-info":false,"indicator-inside":false,"indicator-background":false}},_vm._l((_vm.featured),function(article){return _c('b-carousel-item',{key:article.id,staticClass:"article-carousel-item"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"card is-flex column-container"},[_c('div',{staticClass:"column article-image"},[_c('router-link',{attrs:{"to":{
                  name: 'article',
                  params: { slug: article.slug }
                }}},[_c('div',{style:(("" + (article.media[0].url ? ("background-image: url(" + (article.media[0].url) + ")") : '')))})])],1),_c('div',{staticClass:"column article-info"},[_c('router-link',{attrs:{"to":{
                  name: 'article',
                  params: { slug: article.slug }
                }}},[_c('h2',[_vm._v(_vm._s(article.title))])]),_c('p',{staticClass:"byline"},[_vm._v("by "),_c('router-link',{attrs:{"to":{
                    name: 'user_profile',
                    params: { username: article.authors[0].username }
                  }}},[_vm._v(" "+_vm._s(article.authors[0].name)+" ")])],1),_c('div',{staticClass:"summary"},[_c('router-link',{attrs:{"to":{
                    name: 'article',
                    params: { slug: article.slug }
                  }}},[_c('p',[_vm._v(_vm._s(article.summary))])])],1)],1)])])])}),1)],1):_vm._e(),_c('section',[_c('h2',[_vm._v("Recent Articles")]),(!_vm.searchTerm)?_c('ArticleList',{attrs:{"where":_vm.where.recent}}):_c('ArticleList',{attrs:{"where":_vm.where.filtered}})],1),(!_vm.$auth.isAthenticated)?_c('div',{staticClass:"boxed mb-4 mt-5 p-3",staticStyle:{"width":"60%","margin-left":"auto","margin-right":"auto"}},[_c('h4',{staticClass:"has-text-centered is-size-4 has-text-weight-medium"},[_vm._v(" Not a member of Shut Up & Write!? ")]),_c('p',{staticClass:"has-text-centered is-size-6 has-text-grey"},[_vm._v(" Sign up for a free membership and find the support to keep you writing. ")]),_c('div',{staticClass:"is-flex is-justify-content-center mt-3"},[_c('div',{staticClass:"button is-info has-text-weight-bold mr-3",on:{"click":_vm.login}},[_vm._v(" Sign Up Now ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }